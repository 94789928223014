import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpage';
import Footer from './footer';


function App() {

    const [productData, setProductData] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const productId = queryParameters.get("id")

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://api.arivomkadai.com/editproduct/index.php?id=${productId}`);
            setProductData(response.data);
            //console.log(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, [productId]);

      console.log(productData);

    return (
       <>
    <div class="sidebars">
	  <a href="#">
	    <h3 class="bar-content">Online Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main">
        <div class="page-header breadcrumb-wrap">
            <div class="container">
                <div class="breadcrumb">
                    <a href='index.html' rel='nofollow'><i class="fi-rs-home mr-5"></i>Home</a>
                    <span></span> <a href='/'>{productData && ( productData.data[0].category )}</a> <span></span> {productData && ( productData.data[0].product )}
                </div>
            </div>
        </div>
        <div class="container mb-30">
            <div class="row">
                <div class="col-xl-12 col-lg-12 m-auto">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="product-detail accordion-detail">
                                <div class="row mb-50 mt-30">
                                    <div class="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                                        <div class="detail-gallery">
                                            <span class="zoom-icon"><i class="fi-rs-search"></i></span>
                                          
                                            <div class="product-image-slider">
                                                <figure class="border-radius-10">
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image}`}  alt={productData.data[0].p_id} /> 
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                   {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image1}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image2}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image3}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </figure>
                                                <figure class="border-radius-10">
                                                {productData && (  
                                                       <video src={`https://api.arivomkadai.com/assets/video/${productData.data[0].video}`} width="100%" autoPlay loop muted></video> 
                                                    )}
                                                </figure>
                                            </div>
                                  
                                            <div class="slider-nav-thumbnails">
                                                <div>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </div>
                                                <div>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image1}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </div>
                                                <div>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image2}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </div>
                                                <div>
                                                    {productData && (  
                                                       <img src={`https://api.arivomkadai.com/assets/images/front/${productData.data[0].image3}`} alt={productData.data[0].p_id} /> 
                                                    )}
                                                </div>
                                                <div>
                                                    {productData && (  
                                                       <video src={`https://api.arivomkadai.com/assets/video/${productData.data[0].video}`} width="100%" autoPlay loop muted ></video>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-8 col-sm-12 col-xs-12">
                                        <div class="detail-info pr-30 pl-30">
                                            <span class="stock-status out-stock"> {productData && ( productData.data[0].p_id )}</span>
                                            <h2 class="title-detail">{productData && ( productData.data[0].product )}</h2>
                                            <div class="product-detail-rating">
                                                <div class="product-rate-cover text-end">
                                                    <div class="product-rate d-inline-block">
                                                        <div class="product-rating" style={{width: productData && ( productData.data[0].rating)*20 + '%'}}></div>
                                                    </div>
                                                    <span class="font-small ml-5 text-muted"> ({productData && ( productData.data[0].review )} reviews)</span>
                                                </div>
                                            </div>
                                            <div class="clearfix product-price-cover">
                                                <div class="product-price primary-color float-left">
                                                    <span class="current-price text-brand">₹ {productData && ( productData.data[0].price )}</span>
                                                </div>
                                            </div>
                                            <div class="short-desc mb-30">
                                                <p class="font-lg">{productData && ( productData.data[0].content)}</p>
                                            </div>
                                            <div class="detail-extralink mb-50">
                                               <a href={productData && ( productData.data[0].link )} target="_blank"> 
                                                  <div class="product-extra-link2">
                                                    <button class="button button-add-to-cart"><i class="fi-rs-shopping-cart"></i>Buy Now</button>
                                                  </div>
                                                </a>
                                            </div>
                                           
                                        </div>
                                     
                                    </div>
                                </div>
                                
                                <div class="row mt-60">
                                    <div class="col-12">
                                        <h2 class="section-title style-1 mb-30">Related products</h2>
                                    </div>
                                    <div class="col-12">
                                        <div class="row related-products">
                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom">
                                                            <a href='shop-product-right.html' tabindex='0'>
                                                                <img class="default-img" src="assets/imgs/shop/product-2-1.jpg" alt="" />
                                                                <img class="hover-img" src="assets/imgs/shop/product-2-2.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="product-action-1">
                                                            <a aria-label="Quick view" class="action-btn small hover-up" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i class="fi-rs-search"></i></a>
                                                            <a aria-label='Add To Wishlist' class='action-btn small hover-up' href='shop-wishlist.html' tabindex='0'><i class="fi-rs-heart"></i></a>
                                                            <a aria-label='Compare' class='action-btn small hover-up' href='shop-compare.html' tabindex='0'><i class="fi-rs-shuffle"></i></a>
                                                        </div>
                                                        <div class="product-badges product-badges-position product-badges-mrg">
                                                            <span class="hot">Hot</span>
                                                        </div>
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href='shop-product-right.html' tabindex='0'>Ulstra Bass Headphone</a></h2>
                                                        <div class="rating-result" title="90%">
                                                            <span> </span>
                                                        </div>
                                                        <div class="product-price">
                                                            <span>$238.85 </span>
                                                            <span class="old-price">$245.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom">
                                                            <a href='shop-product-right.html' tabindex='0'>
                                                                <img class="default-img" src="assets/imgs/shop/product-3-1.jpg" alt="" />
                                                                <img class="hover-img" src="assets/imgs/shop/product-4-2.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="product-action-1">
                                                            <a aria-label="Quick view" class="action-btn small hover-up" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i class="fi-rs-search"></i></a>
                                                            <a aria-label='Add To Wishlist' class='action-btn small hover-up' href='shop-wishlist.html' tabindex='0'><i class="fi-rs-heart"></i></a>
                                                            <a aria-label='Compare' class='action-btn small hover-up' href='shop-compare.html' tabindex='0'><i class="fi-rs-shuffle"></i></a>
                                                        </div>
                                                        <div class="product-badges product-badges-position product-badges-mrg">
                                                            <span class="sale">-12%</span>
                                                        </div>
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href='shop-product-right.html' tabindex='0'>Smart Bluetooth Speaker</a></h2>
                                                        <div class="rating-result" title="90%">
                                                            <span> </span>
                                                        </div>
                                                        <div class="product-price">
                                                            <span>$138.85 </span>
                                                            <span class="old-price">$145.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6">
                                                <div class="product-cart-wrap hover-up">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom">
                                                            <a href='shop-product-right.html' tabindex='0'>
                                                                <img class="default-img" src="assets/imgs/shop/product-4-1.jpg" alt="" />
                                                                <img class="hover-img" src="assets/imgs/shop/product-4-2.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="product-action-1">
                                                            <a aria-label="Quick view" class="action-btn small hover-up" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i class="fi-rs-search"></i></a>
                                                            <a aria-label='Add To Wishlist' class='action-btn small hover-up' href='shop-wishlist.html' tabindex='0'><i class="fi-rs-heart"></i></a>
                                                            <a aria-label='Compare' class='action-btn small hover-up' href='shop-compare.html' tabindex='0'><i class="fi-rs-shuffle"></i></a>
                                                        </div>
                                                        <div class="product-badges product-badges-position product-badges-mrg">
                                                            <span class="new">New</span>
                                                        </div>
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href='shop-product-right.html' tabindex='0'>HomeSpeak 12UEA Goole</a></h2>
                                                        <div class="rating-result" title="90%">
                                                            <span> </span>
                                                        </div>
                                                        <div class="product-price">
                                                            <span>$738.85 </span>
                                                            <span class="old-price">$1245.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-12 col-sm-6 d-lg-block d-none">
                                                <div class="product-cart-wrap hover-up mb-0">
                                                    <div class="product-img-action-wrap">
                                                        <div class="product-img product-img-zoom">
                                                            <a href='shop-product-right.html' tabindex='0'>
                                                                <img class="default-img" src="assets/imgs/shop/product-5-1.jpg" alt="" />
                                                                <img class="hover-img" src="assets/imgs/shop/product-3-2.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="product-action-1">
                                                            <a aria-label="Quick view" class="action-btn small hover-up" data-bs-toggle="modal" data-bs-target="#quickViewModal"><i class="fi-rs-search"></i></a>
                                                            <a aria-label='Add To Wishlist' class='action-btn small hover-up' href='shop-wishlist.html' tabindex='0'><i class="fi-rs-heart"></i></a>
                                                            <a aria-label='Compare' class='action-btn small hover-up' href='shop-compare.html' tabindex='0'><i class="fi-rs-shuffle"></i></a>
                                                        </div>
                                                        <div class="product-badges product-badges-position product-badges-mrg">
                                                            <span class="hot">Hot</span>
                                                        </div>
                                                    </div>
                                                    <div class="product-content-wrap">
                                                        <h2><a href='shop-product-right.html' tabindex='0'>Dadua Camera 4K 2024EF</a></h2>
                                                        <div class="rating-result" title="90%">
                                                            <span> </span>
                                                        </div>
                                                        <div class="product-price">
                                                            <span>$89.8 </span>
                                                            <span class="old-price">$98.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </main>

    <Footer />

    </div>
    </>
    );
}

export default App;