import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './headerpage';
import Footer from './footer';


function App() {

    const [productData, setproductData] = useState(null);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/product/');
      setproductData(response.data);
      console.log(response.data);
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

  const intervalId = setInterval(() => {
    fetchData();
  }, 5000);
  return () => clearInterval(intervalId);

}, []);



    return (
       <>
    <div class="sidebars">
	  <a href="#">
	    <h3 class="bar-content">Online Products</h3>
	  </a>
  </div>
  <div class="main-bar">

	<Header  />

    <main class="main mt-bar">
        <section class="home-slider position-relative mb-30 mt-50">
            <div class="container">
                <div class="home-slide-cover mt-30">
                    <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                        <div class="single-hero-slider single-animation-wrap" style={{backgroundImage: 'url(assets/imgs/slider/slider-1.png)'}}>
                            <div class="slider-content">
                                <h1 class="display-1 mb-40">
                                   Online<br />
                                   Shopping
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  
        <section class="banners mb-25">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                            <img src="assets/imgs/banner/banner-1.png" alt="" />
                            <div class="banner-text">
                                <h4>
                                    Everyday Fresh & <br />Clean with Our<br />
                                    Products
                                </h4>
                                <a class='btn btn-xs' href='shop-grid-right.html'>Shop Now <i class="fi-rs-arrow-small-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="banner-img wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                            <img src="assets/imgs/banner/banner-2.png" alt="" />
                            <div class="banner-text">
                                <h4>
                                    Make your Breakfast<br />
                                    Healthy and Easy
                                </h4>
                                <a class='btn btn-xs' href='shop-grid-right.html'>Shop Now <i class="fi-rs-arrow-small-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-md-none d-lg-flex">
                        <div class="banner-img mb-sm-0 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <img src="assets/imgs/banner/banner-3.png" alt="" />
                            <div class="banner-text">
                                <h4>The best Organic <br />Products Online</h4>
                                <a class='btn btn-xs' href='shop-grid-right.html'>Shop Now <i class="fi-rs-arrow-small-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        <section class="product-tabs section-padding position-relative">
            <div class="container">
                <div class="section-title style-2 wow animate__animated animate__fadeIn">
                    <h3>Popular Products</h3>
                </div>
             
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                        <div class="row product-grid-4">
						
						{productData && productData.data && productData.data.map(product => (
                            <div class="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                <div class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                    <div class="product-img-action-wrap">
                                        <div class="product-img product-img-zoom">
                                            <a href={`product?id=${product.p_id}`}>
                                                <img class="default-img" src={`https://api.arivomkadai.com/assets/images/front/${product.image}`} alt={product.product} />
                                                <img class="hover-img" src={`https://api.arivomkadai.com/assets/images/front/${product.image}`} alt={product.product} />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="product-content-wrap">
                                       
                                        <h2><a href={`product?id=${product.p_id}`}>{product.product}</a></h2>
                                        <div class="product-rate-cover">
                                            <div class="product-rate d-inline-block">
                                                <div class="product-rating" style={{width: product.rating*20 +'%'}}></div>
                                            </div>
                                            <span class="font-small ml-5 text-muted"> ({product.review})</span>
                                        </div>
                                       
                                        <div class="product-card-bottom">
                                            <div class="product-price">
                                                <span>₹ {product.price}</span>
                                            </div>
                                            <div class="add-cart">
                                                <a class='add' href={`product?id=${product.p_id}`}><i class="fi-rs-shopping-cart mr-5"></i>View </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            ))}
                          
                        </div>
                  
                    </div>
                   
                </div>
             
            </div>
        </section>
       
    </main>

    <Footer />

    </div>
    </>
    );
}

export default App;