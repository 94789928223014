import React from 'react';
import Header from './header';
import Videos from '../videos/avivomvideo.mp4';


function App() {

    return (
       <>
   
	<Header  />
	

    <main class="main">
        <section class="home-slider position-relative mb-30">
            <div class="container">
                <div class="home-slide-cover mt-30">
                    <div class="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                        <div class="single-hero-slider single-animation-wrap" >
						 <div class="background-video">
                           <video src={Videos} muted autoPlay loop id="bgVideo" ></video>
                         </div>
						   
                            <div class="slider-content">



                            <div id="hero_title" class="mb-40">
                                <h1 class="ah-headline title text-white clip is-full-width ">
                                    <span> We've Most&nbsp;</span> 
                                    <span class="ah-words-wrapper">
                                        <b class="is-visible"> Selling</b>
                                        <b> Genuine</b>
                                        <b> Trending</b>
                                        <b> Useful</b>
                                    </span>
									Products!
                                </h1>
                            </div>

                                <p class=" text-white mb-65">We Make Your Shopping Simple.</p>
                              
                                <a href="/online-products" class="btn mb-20 mr-20">Online Products</a>
                                <a href="" class="btn mb-20" type="submit">Offline Products</a>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
       
 
    </main>
    </>
    );
}

export default App;