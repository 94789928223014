import React, { useEffect, useState } from 'react';


function App() {

    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
      setSidebarVisible(!isSidebarVisible); 
    };
    
    const openModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
    };

    const closeModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.remove('show');
            modal.style.display = 'none';
            document.body.classList.remove('modal-open');
        }
    };

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            if (scroll < 200) {
                setIsSticky(false);
                // Add logic to close dropdowns if needed
            } else {
                setIsSticky(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (



<>

<div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                <div class="modal-body">
                   <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Contact Us</h1>
                                            <p class="mb-30">Let's talk about New Shopping Experience.</p>
                                        </div>
                                        <form method="post">
										  <div class="row">
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input type="text" required="" name="name" placeholder="Enter your name..." />
                                              </div>
                                            </div>
										    <div class="col-lg-6 col-md-6">
                                              <div class="form-group">
                                                <input required="" type="text" name="number" placeholder="Enter your WhatsApp Number..." />
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6">
											  <div class="form-group">
                                                <select required="" name="type">
												  <option selected hidden disabled>Select Purpose</option>
											      <option>Product or Service Promotion</option>
											      <option>Sell your products in our website</option>
											      <option>Product Enquiry for shopping</option>
												</select>
                                              </div>
                                            </div>
											<div class="col-lg-6 col-md-6" align="center">
                                              <div class="form-group">
                                                <input required="" type="text" name="socialmedia" placeholder="Enter your Social Media Links..." />
                                              </div>
                                            </div>
                                          </div>
											
											<div class="form-group">
                                                <textarea rows="2" name="message" placeholder="Enter your Queries..." ></textarea>
                                            </div>
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Submit Now</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                </div>
            </div>
        </div>
    </div>



      <header class="header-area header-style-1 header-height-2">
        <div class="header-middle header-middle-ptb-1 d-none d-lg-block" style={{background:'#fff'}}>
            <div class="container">
                <div class="header-wrap">
                    <div class="logo logo-width-1 col-3">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type='[ "Arivomkadai", "அறிவோம்கடை" ]'>
                              <span class="wrap"></span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-right">
                        <div class="search-style-2">
                            <form action="#">
                                <input type="text" placeholder="Search for items..." />
                            </form>
                        </div>
                        <div class="header-action-right">
                            <div class="header-action-2">
                                <div class="search-location">
                                    <form action="#">
                                       
                                    </form>
                                </div>
                                
                                <div class="header-action-icon-2">
                                    <a href='#'>
                                        <img class="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg" />
                                        <span class="pro-count blue">6</span>
                                    </a>
                                    <a href='#'><span class="lable"></span></a>
                                </div>
                                <div class="header-action-icon-2">
                                    <a class='mini-cart-icon' href='#'>
                                        <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                                        <span class="pro-count blue">2</span>
                                    </a>
                                    <a href='#'><span class="lable"></span></a>
                                    <div class="cart-dropdown-wrap cart-dropdown-hm2">
                                        <ul>
                                            <li>
                                                <div class="shopping-cart-img">
                                                    <a href='#'><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></a>
                                                </div>
                                                <div class="shopping-cart-title">
                                                    <h4><a href='#'>Daisy Casual Bag</a></h4>
                                                    <h4><span>1 × </span>$800.00</h4>
                                                </div>
                                                <div class="shopping-cart-delete">
                                                    <a href="#"><i class="fi-rs-cross-small"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="shopping-cart-img">
                                                    <a href='#'><img alt="Nest" src="assets/imgs/shop/thumbnail-2.jpg" /></a>
                                                </div>
                                                <div class="shopping-cart-title">
                                                    <h4><a href='#'>Corduroy Shirts</a></h4>
                                                    <h4><span>1 × </span>$3200.00</h4>
                                                </div>
                                                <div class="shopping-cart-delete">
                                                    <a href="#"><i class="fi-rs-cross-small"></i></a>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="shopping-cart-footer">
                                            <div class="shopping-cart-total">
                                                <h4>Total <span>$4000.00</span></h4>
                                            </div>
                                            <div class="shopping-cart-button">
                                                <a class='outline' href='#'>View cart</a>
                                                <a href='#'>Checkout</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="header-action-icon-2">
                                    <a href='#'>
                                        <img class="svgInject" alt="Nest" src="assets/imgs/theme/icons/icon-user.svg" />
                                    </a>
                                    <a href='#'><span class="lable ml-0"></span></a>
                                    <div class="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                        <ul>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-user mr-10"></i>My Account</a>
                                            </li>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-location-alt mr-10"></i>Order Tracking</a>
                                            </li>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-label mr-10"></i>My Voucher</a>
                                            </li>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-heart mr-10"></i>My Wishlist</a>
                                            </li>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-settings-sliders mr-10"></i>Setting</a>
                                            </li>
                                            <li>
                                                <a href='#'><i class="fi fi-rs-sign-out mr-10"></i>Sign out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-bottom header-bottom-bg-color sticky-bar" className={`sticky-bar ${isSticky ? 'stick' : ''}`} style={{paddingTop:'20px', paddingBottom:'20px'}}>
            <div class="container">
                <div class="header-wrap header-space-between position-relative">
                    <div class="logo logo-width-1 d-block d-lg-none">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type='[ "Arivomkadai", "அறிவோம்கடை" ]'>
                              <span class="wrap"></span>
                              </a>
                            </h4>
                    </div>
                    <div class="header-nav d-none d-lg-flex">
                        <div class="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                            <nav>
                                <ul>
                                    <li class="hot-deals"><img src="assets/imgs/theme/icons/icon-hot.svg" alt="hot deals" /><a href='#'>Deals</a></li>
                                    <li><a href='/'>Tech</a></li>
                                    <li><a href='#'>Home & Kitchen</a></li>
                                    <li><a href='#'>Kids</a></li>
                                    <li><a href='#'>Gifts</a></li>
                                    <li><a href='#'>Personal care</a></li>
                                    <li><a href='#'>Health</a></li>
                                    <li><a href='#'>Automobile</a></li>
                                    <li><a href='#'>Fashions</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="hotline d-none d-lg-flex">
                      <a id="openModalButton" onClick={openModal}><img src="assets/imgs/theme/icons/icon-headphone.svg" alt="hotline"  /></a>
                    </div>
                    <div class="header-action-icon-2 d-block d-lg-none">
                        <div class="burger-icon burger-icon-white" onClick={toggleSidebar}>
                            <span class="burger-icon-top"></span>
                            <span class="burger-icon-mid"></span>
                            <span class="burger-icon-bottom"></span>
                        </div>
                    </div>
                    <div class="header-action-right d-block d-lg-none">
                        <div class="header-action-2">
                            <div class="header-action-icon-2">
                                <a href='#'>
                                    <img alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg" />
                                    <span class="pro-count white">4</span>
                                </a>
                            </div>
                            <div class="header-action-icon-2">
                                <a class="mini-cart-icon" href="#">
                                    <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                                    <span class="pro-count white">2</span>
                                </a>
                                <div class="cart-dropdown-wrap cart-dropdown-hm2">
                                    <ul>
                                        <li>
                                            <div class="shopping-cart-img">
                                                <a href='#'><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></a>
                                            </div>
                                            <div class="shopping-cart-title">
                                                <h4><a href='#'>Plain Striola Shirts</a></h4>
                                                <h3><span>1 × </span>$800.00</h3>
                                            </div>
                                            <div class="shopping-cart-delete">
                                                <a href="#"><i class="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="shopping-cart-img">
                                                <a href='#'><img alt="Nest" src="assets/imgs/shop/thumbnail-4.jpg" /></a>
                                            </div>
                                            <div class="shopping-cart-title">
                                                <h4><a href='#'>Macbook Pro 2024</a></h4>
                                                <h3><span>1 × </span>$3500.00</h3>
                                            </div>
                                            <div class="shopping-cart-delete">
                                                <a href="#"><i class="fi-rs-cross-small"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="shopping-cart-footer">
                                        <div class="shopping-cart-total">
                                            <h4>Total <span>$383.00</span></h4>
                                        </div>
                                        <div class="shopping-cart-button">
                                            <a href='#'>View cart</a>
                                            <a href='#'>Checkout</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className={isSidebarVisible ? 'mobile-header-active mobile-header-wrapper-style sidebar-visible' : 'mobile-header-active mobile-header-wrapper-style'}>
        <div class="mobile-header-wrapper-inner">
            <div class="mobile-header-top">
                <div class="mobile-header-logo">
                            <h4>
                              <a href="/" class="typewrite" data-period="2000" data-type='[ "Arivomkadai", "அறிவோம்கடை" ]'>
                              <span class="wrap"></span>
                              </a>
                            </h4>
                </div>
                <div class="mobile-menu-close close-style-wrap close-style-position-inherit">
                    <button class="close-style search-close" onClick={toggleSidebar}>
                        <i class="icon-top"></i>
                        <i class="icon-bottom"></i>
                    </button>
                </div>
            </div>
            <div class="mobile-header-content-area">
                <div class="mobile-search search-style-3 mobile-header-border">
                    <form action="#">
                        <input type="text" placeholder="Search for items…" />
                        <button type="submit"><i class="fi-rs-search"></i></button>
                    </form>
                </div>
                <div class="mobile-menu-wrap mobile-header-border">
                  
                    <nav>
                        <ul class="mobile-menu font-heading">
                            <li><a href='/'>Tech</a></li>
                                    <li><a href='#'>Home & Kitchen</a></li>
                                    <li><a href='#'>Kids</a></li>
                                    <li><a href='#'>Gifts</a></li>
                                    <li><a href='#'>Personal care</a></li>
                                    <li><a href='#'>Health</a></li>
                                    <li><a href='#'>Automobile</a></li>
                                    <li><a href='#'>Fashions</a></li>
                        </ul>
                    </nav>
                
                </div>
               
               
                <div class="site-copyright">© 2024  ArivomKadai All Rights Reserved. Websolutions By <a href="https://sninfoserv.com" target="_blank">SN Infoserv</a></div>
            </div>
        </div>
    </div>
</>

);
}

export default App;