import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Online from './pages/online';
import Product from'./pages/product';



function App() {
  return (
   <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route  path="/home" element={<Home />} />
      <Route  path="/online-products" element={<Online />} />
      <Route  path="/product" element={<Product />} />
    </Routes>
   </Router>
  );
}

export default App;